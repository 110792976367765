import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.css";
import menu from "./images/burger-menu-svgrepo-com.svg";
import logo from "./images/logo.png";
import SpModal from "sp-modal";
import Navigation from "./Navigation.js";

export default function Header2() {
  const [openMenu, setOpenMenu] = useState(false);

  const navigate = useNavigate();
  return (
    <div className={styles.headerContainer}>
      <div className={styles.header}>
        <div className={styles.homeLogo} onClick={() => navigate("/")}>
          <img className={styles.logoPic} src={logo} alt="logo" />
        </div>
        <div className={styles.homeNavigation}>
          <img
            className={styles.homeNavigation}
            onClick={() => setOpenMenu(true)}
            height="70px"
            src={menu}
            alt="menu"
          />
        </div>
        <SpModal
          visible={openMenu}
          closeHandler={() => setOpenMenu(false)}
          className="sp-modal__wrapper sp-modal"
        >
          <Navigation />
        </SpModal>
      </div>
    </div>
  );
}
