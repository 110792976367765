import { PDFDocument } from "pdf-lib";
import React, { useState, useEffect } from "react";
import w4 from "./images/w4page1.pdf";

async function spouseFillPDF(formInput) {
  const formUrl = w4;
  const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(formPdfBytes);

  const form = pdfDoc.getForm();
  const firstName = form.getTextField("first_name");
  const lastName = form.getTextField("last_name");
  const address = form.getTextField("address");
  const cityStateZip = form.getTextField("city_state_zip");
  const ssn = form.getTextField("ssn");

  const single = form.getCheckBox("single");
  const married = form.getCheckBox("joint");
  const household = form.getCheckBox("household");

  // const jobs = form.getCheckBox("2jobs");
  const children = form.getTextField("qualifying_children");
  const dependents = form.getTextField("other_dependents");
  const amounts = form.getTextField("total_credits");

  const otherIncome = form.getTextField("other_Income");
  const itemize = form.getTextField("deductions");
  const withholding = form.getTextField("extra_withholding");
  // const signature = form.getTextField("signature");
  const date = form.getTextField("Date");

  firstName.setText(`${formInput?.spouseFirstName}`);
  lastName.setText(`${formInput?.spouseLastName}`);
  address.setText(`${formInput?.spouseAddress}`);
  cityStateZip.setText(
    `${formInput?.spouseCity}, ${formInput?.spouseState}  ${formInput?.spouseZip}`
  );

  ssn.setText(
    formInput?.spouseSsn
      ? formInput.spouseSsn.replace(/^(\d{3})(\d{2})(\d{4})$/, "$1-$2-$3")
      : "" // Default to empty string if ssn is undefined
  );
  // TODO: check based on value
  const spouseIncomePercentage = () => {
    return (
      Number(formInput.income2) /
      (Number(formInput.income1) + Number(formInput.income2))
    );
  };

  if (formInput?.taxFilingStatus === "single") {
    single.check();
    if (formInput.deductionType === "itemized") {
      let netItemizedAmount = Number(formInput.totalAmountToItemize) - 15000;
      itemize.setText(
        String(
          Number(netItemizedAmount > 0 ? netItemizedAmount : 0)
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        )
      );
    } else {
      itemize.setText("0");
    }
  } else if (formInput?.taxFilingStatus === "married") {
    married.check();
    if (formInput.deductionType === "itemized") {
      let proportionSpouseOfStandard = 30000 * spouseIncomePercentage();
      let spouseItemizedNet =
        Number(formInput.amountToItemizeSpouse) -
        Number(proportionSpouseOfStandard);
      itemize.setText(
        String(
          Number(spouseItemizedNet > 0 ? spouseItemizedNet : 0)
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        )
      );
    } else {
      itemize.setText("0");
    }
  } else if (formInput?.taxFilingStatus === "household") {
    household.check();
    if (formInput.deductionType === "itemized") {
      let netItemizedAmount = Number(formInput.totalAmountToItemize) - 22500;
      itemize.setText(
        String(
          Number(netItemizedAmount > 0 ? netItemizedAmount : 0)
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        )
      );
    } else {
      itemize.setText("0");
    }
  }

  // if (formInput.income1 > 0 && formInput.income2 > 0) {
  //   jobs.check();
  // }
  //
  // const totalIncome =
  //   Number(formInput?.income1) +
  //   Number(formInput?.income2) +
  //   Number(formInput?.otherIncome) +
  //   Number(formInput?.otherIncomeSpouse);
  // if (
  //   (formInput?.taxFilingStatus === "married" && totalIncome <= 400000) ||
  //   totalIncome <= 200000
  // ) {
  if (
    formInput?.children > 0 ||
    formInput?.otherIncome > 0 ||
    formInput?.childrenSpouse > 0 ||
    formInput?.otherIncomeSpouse > 0
  ) {
    const childrenCalculation = (
      Math.floor(formInput?.childrenSpouse || 0) * 2000
    )
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    children.setText(childrenCalculation);
    const dependentsCalculation = (
      Math.floor(formInput?.otherDependentsSpouse || 0) * 500
    )
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    dependents.setText(dependentsCalculation);
    const step3 = (
      Math.floor(formInput?.childrenSpouse || 0) * 2000 +
      Math.floor(formInput?.otherDependentsSpouse || 0) * 500 +
      Math.floor(formInput?.creditsSpouse || 0)
    )
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    amounts.setText(step3);
  } else amounts.setText(`0`);
  const otherIncomeSpouseFormatted = String(
    Number(formInput?.otherIncomeSpouse)
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
  otherIncome.setText(otherIncomeSpouseFormatted);
  const withholdingSpouseFormatted = String(
    Number(formInput?.withholdingSpouse)
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
  withholding.setText(withholdingSpouseFormatted);
  // signature.setText(
  //   `${formInput?.spouseFirstName} ${formInput.spouseLastName}`
  // );
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  date.setText(formattedDate);

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const docUrl = URL.createObjectURL(blob);
  return docUrl;
}

function SpouseFillForm({ formInput }) {
  const [pdfInfoSpouse, setPdfInfoSpouse] = useState();

  useEffect(() => {
    const fillAndLoadPDF = async () => {
      const pdfUrl = await spouseFillPDF(formInput);
      setPdfInfoSpouse(pdfUrl);
    };

    fillAndLoadPDF();
  }, [formInput]);

  return (
    <>
      {pdfInfoSpouse && (
        <object
          data={pdfInfoSpouse}
          type="application/pdf"
          style={{ width: "100%", height: "100vh" }}
        >
          <p>
            It appears your browser does not support embedded PDFs. Please{" "}
            <a href={pdfInfoSpouse} target="_blank" rel="noreferrer">
              click here
            </a>{" "}
            to view the spouse Form W-4.
          </p>
        </object>
      )}
    </>
  );
}

export default SpouseFillForm;

//   download form:
// var blob = new Blob([pdfBytes], {type: "application/pdf"});
// var link = document.createElement("a");
// link.href = window.URL.createObjectURL(blob);
// link.download = "myFileName.pdf";
// link.click();
