import React, { useRef } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import videoSrc from "./images/4335_home.mp4";
import headerPic from "./images/banner3.jpg";
import Footer from "./Footer.js";
import thumbnailSrc from "./images/home-thumbnail.png";
import Header2 from "./Header2.js";

import styles from "./Home.module.css";
import "./modal.css";

export default function Home() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    // Fire an event when the button is clicked
    ReactGA.event({
      category: "User",
      action: "Clicked Navigate to Interview",
      label: "Interview Page",
    });
    navigate("/interview");
  };
  const videoRef = useRef(null);
  return (
    <div>
      <div className={styles.homeContainer}>
        <img src={headerPic} alt="hero-pic" className={styles.heroPic} />
        <div className={styles.navContainer}>
          <Header2 />
        </div>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.heroContent}>
          <div className={styles.heroRow}>
            <h1 className={styles.home}>
              Your W-4 - Fast, Easy, and Accurate.
            </h1>

            <p className={styles.home}>
              We take the stress out of tax season for couples filing jointly or
              anyone with multiple jobs - save yourself time and money with our
              easy-to-use form filler.
            </p>
            <div className={styles.cta}>
              <button onClick={handleButtonClick}>GET MY W-4</button>
            </div>
          </div>
        </div>

        <div className={styles.heroContent}>
          <div className={styles.heroRow}>
            <h2 className={styles.home}>
              Filing Jointly? Here’s What You Need To Know.
            </h2>
            <p className={styles.home}>
              Filing jointly? Working multiple jobs? Don’t risk inaccurate
              withholdings! The redesigned Form W-4 no longer uses allowances,
              meaning if both you and your spouse work, more tax should
              typically be withheld to avoid a surprise tax bill.
            </p>
            <p className={styles.home}>
              That’s where W-4Married.com comes in. Our easy, step-by-step tool
              ensures your withholdings are calculated correctly and provides
              you with completed W-4s for you and your spouse — without you
              having to spend time reading through long instructions and
              worksheets. Stay tax-ready. For just $2.99, complete your W-4s
              together in minutes - not hours. Print, email, or save your forms
              instantly—stress-free and accurate!
            </p>
            <div className={styles.cta}>
              <button onClick={handleButtonClick}>LEARN MORE</button>
            </div>
          </div>
        </div>
        <div className={styles.videoText}>
          <div className={styles.heroVideo}>
            <h2 className={styles.home}>Why do I need two W-4s?</h2>
            <video
              ref={videoRef}
              src={videoSrc}
              poster={thumbnailSrc}
              controls
              style={{ width: "100%" }}
            />
          </div>
          <div className={styles.whatYouGet}>
            <h2 className={styles.home}>Features & Benefits:</h2>
            <ul>
              <li className={styles.home}>
                <b>Complete & Accurate</b>: Get your completed W-4s in just a
                few minutes and ensure accurate withholdings - every time.
              </li>
              <li className={styles.home}>
                <b>Professional Guidance</b>: Easily complete your W-4 forms
                with simple prompts that gather all the necessary details.
              </li>
              <li className={styles.home}>
                <b> Personalized W-4 Forms</b>: Receive ready-to-use, accurate
                W-4s for both spouses (you need to do both together)—ready to
                email or save in seconds.
              </li>
              <li className={styles.home}>
                <b>Privacy Guaranteed</b>: Your personal information is never
                stored, ensuring complete confidentiality.
              </li>
              <li className={styles.home}>
                <b>Peace of Mind</b>: Feel confident knowing your withholdings
                are accurate and optimized for your financial goals.
                <br />
                <br />
              </li>
            </ul>
            <div className={styles.cta}>
              <button onClick={handleButtonClick}>GET STARTED NOW</button>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
