import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import styles from "./Interview.module.css";
import preview from "./Preview.module.css";
import w4preview from "./images/w4page1.png";
import accountantpreview from "./images/summaryForm.png";
import "./modal.css";

export default function OrderDetails({
  orderDetailsCloseButton,
  handleChange,
  formValues,
  onApplyPromoCode, // New prop to apply promo code and recalculate total
  onUpdateTotal, // New prop to send the total price back to Interview.js
}) {
  const [orderInfo, setOrderInfo] = useState(formValues);
  const [promoCode, setPromoCode] = useState(""); // Track the entered promo code
  const [promoError, setPromoError] = useState(""); // Error message for invalid promo code
  const [discount, setDiscount] = useState(0); // Discount applied from promo code
  const [totalPrice, setTotalPrice] = useState(2.99); // Default order total
  // const [childrenCalculation, setChildrenCalculation] = useState(0);
  // const [dependentsCalculation, setDependentsCalculation] = useState(0);
  // const [childrenSpouseCalculation, setChildrenSpouseCalculation] = useState(0);
  // const [dependentsSpouseCalculation, setDependentsSpouseCalculation] =
  //   useState(0);
  // Calculate order total based on form values
  const orderTotal = () => {
    if (
      formValues.processSpouseW4 === true &&
      formValues.addAccountantForm === true
    )
      return 6.99;
    else if (
      formValues.processSpouseW4 === true ||
      formValues.addAccountantForm === true
    )
      return 4.99;
    else return 2.99;
  };

  // Update total price dynamically and pass it to Interview.js
  const updateTotalPrice = () => {
    const baseTotal = orderTotal(); // Get the base total
    const discountedTotal = baseTotal - (baseTotal * discount) / 100; // Apply discount if any
    setTotalPrice(discountedTotal); // Set the total price state
    onUpdateTotal(discountedTotal); // Send the updated total to Interview.js
  };

  useEffect(() => {
    updateTotalPrice(); // Recalculate price when formValues or discount changes
  }, [formValues, discount]);

  const handleYesSpouse = () => {
    const processSpouseW4 = formValues.processSpouseW4;
    handleChange({ processSpouseW4: !processSpouseW4 });
  };

  const handleYesReport = () => {
    const addAccountantForm = formValues.addAccountantForm;
    handleChange({ addAccountantForm: !addAccountantForm });
  };

  const makeChange = (evt) => {
    setOrderInfo({ ...orderInfo, [evt.target.name]: evt.target.value });
  };

  const handlePromoCode = async (evt) => {
    evt.preventDefault();
    console.log("✅ handlePromoCode was triggered!");
    console.log("Promo code entered:", promoCode);

    try {
      const response = await fetch(
        "https://stripe-backend-jtzm.onrender.com/apply-promo-code",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ promoCode }), // Send promo code to backend
        }
      );

      const data = await response.json();
      console.log("Server response:", data);

      if (data.error) {
        console.warn("❌ Invalid promo code:", data.error);
        setPromoError("Invalid promo code.");
        setDiscount(0);
      } else {
        console.log("✅ Promo code applied successfully!");
        setPromoError("");
        setDiscount(data.discount);
        updateTotalPrice();

        let promoCodeUsage =
          Number(localStorage.getItem("promoCodeUsage")) || 0;
        if (promoCodeUsage >= 2) {
          console.warn("❌ Promo code maxed out.");
          return;
        }

        promoCodeUsage++;
        localStorage.setItem("promoCodeUsage", promoCodeUsage);
        localStorage.setItem("reviewClicksPromo", 0);

        ReactGA.event({
          category: "User",
          action: `Used promo code: ${promoCode.toUpperCase()}`,
          label: "Promo Code Tracking",
        });

        // ✅ Add a direct function call to onApplyPromoCode
        console.log("📌 Calling onApplyPromoCode NOW...");
        onApplyPromoCode(promoCode, data.discount);
      }
    } catch (error) {
      console.error("❌ Error applying promo code:", error);
      setPromoError("Error applying promo code.");
    }
  };

  // const totalIncome =
  //   Number(formValues?.income1) +
  //   Number(formValues?.income2) +
  //   Number(formValues?.otherIncome) +
  //   Number(formValues?.otherIncomeSpouse);

  const rawChildrenAmount = () => {
    if (formValues?.children > 0) {
      return Math.floor(formValues?.children || 0) * 2000;
    } else {
      return 0;
    }
  };

  const rawDependentsAmount = () => {
    if (formValues?.otherDependents > 0) {
      return Math.floor(formValues?.otherDependents || 0) * 500;
    } else {
      return 0;
    }
  };
  const rawSpouseChildrenAmount = () => {
    if (formValues?.childrenSpouse) {
      return Math.floor(formValues?.childrenSpouse || 0) * 2000;
    } else {
      return 0;
    }
  };

  const rawSpouseDependentsAmount = () => {
    if (formValues?.otherDependentsSpouse) {
      return Math.floor(formValues?.otherDependentsSpouse || 0) * 500;
    } else {
      return 0;
    }
  };
  const childrenCalculation = () =>
    rawChildrenAmount()
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const dependentsCalculation = () =>
    rawDependentsAmount()
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const childrenSpouseCalculation = () =>
    rawSpouseChildrenAmount()
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const dependentsSpouseCalculation = () =>
    rawSpouseDependentsAmount()
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const totalDependentsCalculation = () => {
    const total = rawChildrenAmount() + rawDependentsAmount();
    return total.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const totalSpouseDependentsCalculation = () => {
    const total = rawSpouseChildrenAmount() + rawSpouseDependentsAmount();
    return total.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleChange(orderInfo);

    orderDetailsCloseButton();
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={styles.questionContainer}>
          <h3>Order Details</h3>
          {formValues.withholdingAmount > 0 ? (
            <p>
              Your information indicates that additional withholding applies in
              your circumstance. Check out now to get your completed W-4s.
            </p>
          ) : null}

          <div className={preview.previewContainer}>
            {/* W-4 Preview */}
            <div className={preview.previewItem}>
              <img
                src={w4preview}
                alt="W-4 Preview"
                className={preview.previewImage}
              />
              <div
                className={preview.overlayText}
                style={{ top: "35px", left: "50px" }}
              >
                {formValues.firstName}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "35px", left: "130px" }}
              >
                {formValues.lastName}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "46px", left: "50px" }}
              >
                {formValues.address}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "57px", left: "50px" }}
              >
                {formValues.city} {formValues.state} {formValues.zip}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "169px", left: "178px" }}
              >
                {childrenCalculation()}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "177px", left: "178px" }}
              >
                {dependentsCalculation()}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "190px", left: "225px" }}
              >
                {totalDependentsCalculation()}
              </div>

              <div className={preview.banner}>
                Check Out
                <br />
                Now
              </div>
              {formValues.firstName ? (
                <p className={preview.centered}>{formValues.firstName}’s W-4</p>
              ) : (
                <p className={preview.centered}>Spouse 1’s W-4</p>
              )}
            </div>

            {/* Spouse W-4 Preview */}
            <div className={preview.previewItem}>
              <img
                src={w4preview}
                alt="Spouse W-4 Preview"
                className={preview.previewImage}
              />
              <div
                className={preview.overlayText}
                style={{ top: "35px", left: "50px" }}
              >
                {formValues.spouseFirstName}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "35px", left: "130px" }}
              >
                {formValues.spouseLastName}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "46px", left: "50px" }}
              >
                {formValues.spouseAddress}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "57px", left: "50px" }}
              >
                {formValues.spouseCity} {formValues.spouseState}{" "}
                {formValues.SpouseZip}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "169px", left: "178px" }}
              >
                {childrenSpouseCalculation()}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "177px", left: "178px" }}
              >
                {dependentsSpouseCalculation()}
              </div>
              <div
                className={preview.overlayText}
                style={{ top: "190px", left: "225px" }}
              >
                {totalSpouseDependentsCalculation()}
              </div>
              <div onClick={handleYesSpouse} className={preview.banner}>
                Add to
                <br />
                Check Out
              </div>
              {formValues.firstName ? (
                <p className={preview.centered}>
                  {formValues.spouseFirstName}’s W-4
                </p>
              ) : (
                <p className={preview.centered}>Spouse 2’s W-4</p>
              )}
              <div onClick={makeChange} className={preview.centered}>
                <input
                  type="checkbox"
                  id="processSpouseW4"
                  name="processSpouseW4"
                  checked={formValues.processSpouseW4 === true}
                  onChange={handleYesSpouse}
                />
              </div>
            </div>

            {/* Accountant Report Preview */}
            <div className={preview.previewItem}>
              <img
                src={accountantpreview}
                alt="Accountant Report Preview"
                className={preview.previewImage}
              />
              <div
                className={preview.overlayTextReport}
                style={{ top: "100px", left: "105px" }}
              >
                {Number(formValues.income1).toLocaleString()}
              </div>
              <div
                className={preview.overlayTextReport}
                style={{ top: "100px", left: "150px" }}
              >
                {Number(formValues.income2).toLocaleString()}
              </div>
              <div
                className={preview.overlayTextReport}
                style={{ top: "100px", left: "193px" }}
              >
                {Number(
                  formValues.income1 + formValues.income2
                ).toLocaleString()}
              </div>

              <div onClick={handleYesReport} className={preview.bannerReport}>
                Add to
                <br />
                Check Out
              </div>

              <p className={preview.centered}>Accountant’s Report</p>
              <div onClick={makeChange} className={preview.centered}>
                <input
                  type="checkbox"
                  id="addAccountantForm"
                  name="addAccountantForm"
                  checked={formValues.addAccountantForm === true}
                  onChange={handleYesReport}
                />
              </div>
            </div>
          </div>

          <p>{`Order Total: $${totalPrice.toFixed(2)}`}</p>

          {/* Promo Code Input */}
          <div>
            <input
              type="text"
              placeholder="Enter promo code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              className={styles.promoCodeInput}
              style={{
                marginRight: "10px",
                borderRadius: "15px",
                marginBottom: "10px",
              }}
            />
            <button onClick={handlePromoCode}>Apply Promo Code</button>
            {promoError && <p style={{ color: "red" }}>{promoError}</p>}
          </div>
        </div>
      </form>
    </>
  );
}
