// Updated this file 12/17/24
const singleTable = [
  [200, 850, 1020, 1020, 1020, 1370, 1870, 1870, 1870, 1870, 1870, 2040],
  [850, 1700, 1870, 1870, 2220, 3220, 3720, 3720, 3720, 3720, 3890, 4090],
  [1020, 1870, 2040, 2390, 3390, 4390, 4890, 4890, 4890, 5060, 5260, 5460],
  [1020, 1870, 2390, 3390, 4390, 5390, 5890, 5890, 6060, 6260, 6460, 6660],
  [1220, 3070, 4240, 5240, 6240, 7240, 7880, 8080, 8280, 8480, 8680, 8880],
  [1870, 3720, 4890, 5890, 7030, 8230, 8930, 9130, 9330, 9530, 9730, 9930],
  [1870, 3720, 5030, 6230, 7430, 8630, 9330, 9530, 9730, 9930, 10130, 10580],
  [2040, 4090, 5460, 6660, 7860, 9060, 9760, 9960, 10160, 10950, 11950, 12950],
  [2040, 4090, 5460, 6660, 7860, 9060, 9950, 10950, 11950, 12950, 13950, 14950],
  [
    2040, 4090, 5460, 6660, 8450, 10450, 11950, 12950, 13950, 15080, 16380,
    17680,
  ],
  [
    2040, 4290, 6450, 8450, 10450, 12450, 13950, 15230, 16530, 17830, 19130,
    20430,
  ],
  [
    2720, 5570, 7900, 10200, 12500, 14800, 16600, 17900, 19200, 20500, 21800,
    23100,
  ],
  [
    2970, 6120, 8590, 10890, 13190, 15490, 17290, 18590, 19890, 21190, 22490,
    23790,
  ],
  [
    2970, 6120, 8590, 10890, 13190, 15490, 17290, 18590, 19890, 21190, 22490,
    23790,
  ],
  [
    3140, 6490, 9160, 11660, 14160, 16660, 18660, 20160, 21660, 23160, 24660,
    26160,
  ],
];
// TODO: remember the last case needs to be over 110,000
const singleColumns = [
  9999, 19999, 29999, 39999, 49999, 59999, 69999, 79999, 89999, 99999, 109999,
  120000,
];

// TODO: remember the last case needs to be over 450,000
const singleRows = [
  9999, 19999, 29999, 39999, 59999, 79999, 99999, 124999, 149999, 174999,
  199999, 249999, 399999, 450000,
];

const singleCalculation = (income1, income2) => {
  const higherNumber = Math.max(income1, income2);
  const lowerNumber = Math.min(income1, income2);

  let rowIndex = singleRows.findIndex((range) => higherNumber <= range);
  let columnIndex = singleColumns.findIndex((range) => lowerNumber <= range);

  // If income is higher than all ranges, use the highest available index
  if (rowIndex === -1) {
    rowIndex = singleRows.length;
  }

  if (columnIndex === -1) {
    columnIndex = singleColumns.length;
  }

  // Ensure the indices are within bounds of the singleTable
  rowIndex = Math.min(rowIndex, singleTable.length - 1);
  columnIndex = Math.min(columnIndex, singleTable[rowIndex].length - 1);

  return singleTable[rowIndex][columnIndex];
};

export { singleCalculation };
