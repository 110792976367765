// updated 12/17/24
const marriedTable = [
  [0, 0, 700, 850, 910, 1020, 1020, 1020, 1020, 1020, 1020, 1020],
  [0, 700, 1700, 1910, 2110, 2220, 2220, 2220, 2220, 2220, 2220, 3220],
  [700, 1700, 2760, 3110, 3310, 3420, 3420, 3420, 3420, 3420, 4420, 5420],
  [850, 1910, 3110, 3460, 3660, 3770, 3770, 3770, 3770, 4770, 5770, 6770],
  [910, 2110, 3310, 3660, 3860, 3970, 3970, 3970, 4970, 5970, 6970, 7970],
  [1020, 2220, 3420, 3770, 3970, 4080, 4080, 5080, 6080, 7080, 8080, 9080],
  [1020, 2220, 3420, 3770, 3970, 4080, 5080, 6080, 7080, 8080, 9080, 10080],
  [1020, 2220, 3420, 3770, 3970, 5080, 6080, 7080, 8080, 9080, 10080, 11080],
  [1020, 2220, 3420, 4620, 5820, 6930, 7930, 8930, 9930, 10930, 11930, 12930],
  [
    1870, 4070, 6270, 7620, 8820, 9930, 10930, 11930, 12930, 14010, 15210,
    16410,
  ],
  [
    1870, 4240, 6640, 8190, 9590, 10890, 12090, 13290, 14490, 15690, 16890,
    18090,
  ],
  [
    2040, 4440, 6840, 8390, 9790, 11100, 12300, 13500, 14700, 15900, 17100,
    18300,
  ],
  [
    2040, 4440, 6840, 8390, 9790, 11100, 12300, 13500, 14700, 15900, 17100,
    18300,
  ],
  [
    2040, 4440, 6840, 8390, 9790, 11100, 12300, 13500, 14700, 15900, 17100,
    18300,
  ],
  [
    2040, 4440, 6840, 8390, 9790, 11100, 12300, 13500, 14700, 15900, 17170,
    19170,
  ],
  [
    2040, 4440, 6840, 8390, 9790, 11100, 12470, 14470, 16470, 18470, 20470,
    22470,
  ],
  [
    2790, 6290, 9790, 12440, 14940, 17350, 19650, 21950, 24250, 26550, 28850,
    31150,
  ],
  [
    3140, 6840, 10540, 13390, 16090, 18700, 21200, 23700, 26200, 28700, 31200,
    33700,
  ],
];

// TODO: remember the last case needs to be over 110,000
const marriedColumns = [
  9999, 19999, 29999, 39999, 49999, 59999, 69999, 79999, 89999, 99999, 109999,
  120000,
];

// TODO: remember the last case needs to be over 525,000
const marriedRows = [
  9999, 19999, 29999, 39999, 49999, 59999, 69999, 79999, 99999, 149999, 239999,
  259999, 279999, 299999, 319999, 364999, 524999,
];

const marriedCalculation = (income1, income2) => {
  const higherNumber = Math.max(income1, income2);
  const lowerNumber = Math.min(income1, income2);

  let rowIndex = marriedRows.findIndex((range) => higherNumber <= range);
  let columnIndex = marriedColumns.findIndex((range) => lowerNumber <= range);

  // If income is higher than all ranges, use the highest available index
  if (rowIndex === -1) {
    rowIndex = marriedRows.length;
  }

  if (columnIndex === -1) {
    columnIndex = marriedColumns.length;
  }

  // Ensure the indices are within bounds of the marriedTable
  rowIndex = Math.min(rowIndex, marriedTable.length - 1);
  columnIndex = Math.min(columnIndex, marriedTable[rowIndex].length - 1);

  return marriedTable[rowIndex][columnIndex];
};

export { marriedCalculation };
